import React from "react";

import Layout from "../lib/components/Layout";
import PageTitlePanel from '../lib/components/PageTitlePanel';
import Storage from '../lib/storage';

function Page({ location }) {

  // React hook for checking if screen is mobile size
  const [width, setWidth] = React.useState(null);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
    }
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth)
    }

  }, []);
  const isMobile = width <= 768;

  return (
    <Layout
      title={Storage.pages.about.metadata.title}
      description={Storage.pages.about.metadata.description}
      pageUrl={location.pathname}
      fixedButton="backToHome"
      bgNavSelector="titlePanel"
    >
      <PageTitlePanel
        title={Storage.pages.termsAndConditions.titlePanel.title}
        backgroundImg={Storage.pages.termsAndConditions.titlePanel.backgroundImg}
        isMobile={isMobile}
        relativeUrl={location.pathname}
      />

      <div className="relative w-full nexus-bg h-page-p v-page-p">
        <div className="flex flex-col items-start text-left gap-8">
          {/* <h1 className="font-bold nexus-text uppercase select-none">{Storage.pages.termsAndConditions.metadata.description}</h1> */}
          <h1 className="font-bold nexus-text uppercase select-none">Intellectual Property Rights</h1>
          <h2 className="text-base nexus-text select-none">All copyrights, trademarks, patents and other intellectual property rights in and on our website and all content and software located on the site shall remain the sole property of Nexus Commercial Services or its licensors. The use of our trademarks, content and intellectual property is forbidden without the express written consent from Nexus Commercial Services Services. You must not:</h2>
          <ul className="nexus-text text-sm text-left list-disc grid list-inside gap-2 select-none">
            <li>Republish material from our website without prior written consent.</li>
            <li>Sell or rent material from our website.</li>
            <li>Reproduce, duplicate, create derivative, copy or otherwise exploit material on our website for any purpose.</li>
            <li>Redistribute any content from our website, including onto another website.</li>
          </ul>

          <h1 className="font-bold nexus-text uppercase select-none">Acceptable Use</h1>
          <p className="text-base nexus-text select-none">You agree to use our website only for lawful purposes, and in a way that does not infringe the rights of, restrict or inhibit anyone else’s use and enjoyment of the website. Prohibited behaviour includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content or disrupting the normal flow of dialogue within our website.</p>
          <p className="text-base nexus-text select-none">You must not use our website to send unsolicited commercial communications. You must not use the content on our website for any marketing related purpose without our express written consent.</p>

          <h1 className="font-bold nexus-text uppercase select-none">Restricted Access</h1>
          <p className="text-base nexus-text select-none">We may in the future need to restrict access to parts or all of our website, and reserve full rights to do so. If, at any point, we provide you with a username and password for you to access restricted areas of our website, you must ensure that both your username and password are kept confidential.</p>
          <p className="text-base nexus-text select-none">You must not use our website to send unsolicited commercial communications. You must not use the content on our website for any marketing related purpose without our express written consent.</p>

          <h1 className="font-bold nexus-text uppercase select-none">Use of Testimonials</h1>
          <p className="text-base nexus-text select-none">In accordance with with the FTC guidelines concerning the use of endorsements and testimonials in advertising, please be aware of the following:</p>
          <ul className="nexus-text text-sm text-left list-disc grid list-inside gap-2 select-none">
            <li>Testimonials that appear on this site are received via text, audio or video submission. They are individual experiences, reflecting real life experiences of those who have used our products and/or services in some way. They are individual results and results do vary. We do not claim that they are typical results. The testimonials are not necessarily representative of all of those who will use our products and/or services.</li>
            <li>The testimonials displayed in any form on this site (text, audio, video or other) are reproduced verbatim, except for correction of grammatical or typing errors. Some may have been shortened. In other words, not the whole message received by the testimonial writer is displayed when it seems too lengthy or not the whole statement seems relevant for the general public.</li>
          </ul>
          <p className="text-base nexus-text select-none">Nexus Commercial Services is not responsible for any of the opinions or comments posted on Nexus Commercial Services.</p>
          <p className="text-base nexus-text select-none">Nexus Commercial Services is not a forum for testimonials, however, provides testimonials as a means for customers to share their experiences with one another. To protect against abuse, all testimonials appear after they have been reviewed by management of Nexus Commercial Services.</p>
          <p className="text-base nexus-text select-none">Nexus Commercial Services does not share the opinions, views or commentary of any testimonials on Nexus Commercial Services - the opinions are strictly the views of the testimonial source.</p>
          <p className="text-base nexus-text select-none">The testimonials are never intended to make claims that our products and/or services can be used to diagnose, treat, cure, mitigate or prevent any disease. Any such claims, implicit or explicit, in any shape or form, have not been clinically tested or evaluated.</p>

          <h1 className="font-bold nexus-text uppercase select-none">How Do We Protect Your Information and Secure Information Transmissions?</h1>
          <p className="text-base nexus-text select-none">Email is not recognized as a secure medium of communication. For this reason, we request that you do not send private information to us by email. However, doing so is allowed, but at your own risk. Some of the information you may enter on our website may be transmitted securely via a secure medium known as Secure Sockets Layer, or SSL. Credit Card information and other sensitive information is never transmitted via email.</p>
          <p className="text-base nexus-text select-none">Nexus Commercial Services may use software programs to create summary statistics, which are used for such purposes as assessing the number of visitors to the different sections of our site, what information is of most and least interest, determining technical design specifications, and identifying system performance or problem areas.</p>
          <p className="text-base nexus-text select-none">For site security purposes and to ensure that this service remains available to all users, Nexus Commercial Services uses software programs to monitor network traffic to identify unauthorized attempts to upload or change information, or otherwise cause damage.</p>

          <h1 className="font-bold nexus-text uppercase select-none">Disclaimer and Limitation of Liability</h1>
          <p className="text-base nexus-text select-none">Nexus Commercial Services makes no representations, warranties, or assurances as to the accuracy, currency or completeness of the content contain on this website or any sites linked to this site. All the materials on this site are provided "as is" without any express or implied warranty of any kind, including warranties of merchantability, non-infringement of intellectual property or fitness for any particular purpose. In no event shall Nexus Commercial Services or its agents or associates be liable for any damages whatsoever (including, without limitation, damages for loss of profits, business interruption, loss of information, injury or death) arising out of the use of or inability to use the materials, even if Nexus Commercial Services has been advised of the possibility of such loss or damages.</p>

          {/* <p className="font-bold text-sm nexus-text select-none">Etiam rhoncus risus eget turpis dictum, in condimentum eros fringilla. Donec quis mattis justo. Proin quis nibh vel tellus dictum porttitor. Nulla quis euismod purus?</p> */}
        </div>
      </div>

    </Layout>
  )
}

export default Page
